import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../sections/Footer/Footer";
import Poptavka from "../sections/Poptavka/Poptavka";
import Topbar from "../sections/Topbar/Topbar";

const poptavka = () => {
  return (
    <>
      <Helmet>
        <title>Poptávka tiskového řešení | Pronajmisitiskarnu.cz</title>
        <meta name="google-site-verification" content="GXo6IbZumpEUZk7Vo9T-NctewrMAN0TthHrDLVj1fZY" />
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NSHS6PW');`}</script>
      </Helmet>
      <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NSHS6PW" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      <header>
        <Topbar />
      </header>
      <main>
        <Poptavka />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default poptavka;
