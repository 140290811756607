import React, { useState } from "react";
import css from "./Poptavka.module.scss";
import { Background, Button, Container, Slider } from "../../components/Core";
const Poptavka = () => {
  const [slider, setSlider] = useState(0);
  const handleSliders = (e) => {
    let value = e.target.value;
    setSlider(value);
  };
  return (
    <Background color="light">
      <Container>
        <div className={css.poptavka}>
          <div className={css.box}>
            <h2>Nezávazná poptávka</h2>
            <form action="https://formsubmit.co/m.horazdovsky@vseprotisk.cz" method="POST">
              <div className={css.main}>
                <label htmlFor="jmeno">Jméno / Firma *</label>
                <input type="text" id="jmeno" name="Jméno" required />
                <label htmlFor="telefon">Telefon *</label>
                <input type="tel" id="telefon" name="Telefon" required />
                <label htmlFor="email">Email *</label>
                <input type="email" id="email" name="Email" required />
                <label htmlFor="info">Dotazy a požadavky</label>
                <textarea name="Info" id="info"></textarea>
                <Button as="submit">Odeslat poptávku</Button>
              </div>
              <div className={css.secondary}>
                <div className={css.person}>
                  <img src="https://vseprotisk.cz/img/user/inc/kontakty/janecek.png"></img>
                  <span>Dalibor Janeček</span>
                  <p>Specialista na tisková řešení</p>
                  <a href="tel:+420774704880">+420 774 704 880</a><br></br>
                  <a href="mailto:d.janecek@vseprotisk.cz">d.janecek@vseprotisk.cz</a>
                </div>
                <input type="hidden" name="_template" value="table" />
                <input type="hidden" name="_captcha" value="false" />
                <input type="hidden" name="_subject" value="NOVÁ POPTÁVKA - bezstarosti.vseprotisk.cz" />
                <input type="hidden" name="_cc" value="j.riman@vseprotisk.cz,o.laurinova@vseprotisk.cz,d.janecek@vseprotisk.cz" />
                <input type="hidden" name="_next" value="http://bezstarosti.vseprotisk.cz/dekujeme/" />
              </div>
            </form>
          </div>
          <p className={css.gdp}>
            Odesláním souhlasíte se zpracováním osobních údajů potřebných pro vyřízení vaší poptávky. Údaje dále nijak nezpracováváme a nebudou použity pro
            žádné další marketingové či jiné účely.
          </p>
        </div>
      </Container>
    </Background>
  );
};

export default Poptavka;
